import { serialize } from './formHelpers'
import request from './request'
import { FeedbackResponse } from './types/interfaces'

export const feedbackForm = (contactForm: HTMLFormElement | null): void => {
  if (contactForm != null) {
    contactForm.addEventListener('submit', event => {
      event.preventDefault()
      const button = contactForm.getElementsByTagName('button')[0]
      button.disabled = true
      if (button.getAttribute('data-disable-with') != null) {
        button.textContent = button.getAttribute('data-disable-with')
      }

      const formData: { [k: string]: string } = {}
      const fields = Array.prototype.slice.call(contactForm.elements, 0)
      Array.prototype.forEach.call(fields, function (element: {
        name: string
        value: string
      }) {
        formData[element.name] = element.value
      })

      request(
        'POST',
        contactForm.action,
        serialize(formData),
        function pluginFeedbackResponse (data: FeedbackResponse): void {
          if (data?.message != null) {
            const responseOutput: HTMLElement | null = contactForm.parentElement
            const fragment = document.createDocumentFragment()
            contactForm.style.display = 'none'

            const response = document.createElement('p')
            response.className = 'user-form__response'
            response.textContent = data.message
            fragment.appendChild(response)
            if (responseOutput != null) {
              responseOutput.appendChild(fragment)
            }
          } else if (data != null) {
            hidePreviousErrors()
            const errorMessage = document.createElement('p')
            errorMessage.className = 'user-form__error'
            const errors = data.errors

            if (data.captcha != null && errors != null) {
              errorMessage.textContent = errors[0]
            } else if (data.captcha == null && errors != null) {
              errorMessage.textContent = errors[0]
            }

            contactForm.insertAdjacentElement('afterbegin', errorMessage)
            const btn = contactForm.querySelector('button')
            if (btn != null) {
              btn.disabled = false
              if (btn.getAttribute('data-original') != null) {
                btn.textContent = btn.getAttribute('data-original')
              }
            }
          }
        }
      )
    })
  }
}

export function hidePreviousErrors (): void {
  const prevErrors = document.querySelectorAll('.user-form__error')

  Array.prototype.forEach.call(prevErrors, function (error: HTMLElement) {
    error.style.display = 'none'
  })
}
