export const commentReplyLink = (
  link: HTMLAnchorElement | HTMLButtonElement
): void => {
  link.addEventListener('click', event => {
    const commentId = link.getAttribute('data-commentId')

    event.preventDefault()

    if (commentId != null) {
      const nestedForm: HTMLElement | null = document.getElementById(
        `comment-${commentId}`
      )
      if (nestedForm != null) {
        nestedForm.style.display = 'block'
      }
    }
  })
}
