/* eslint-disable @typescript-eslint/indent */
import 'rucksack/icons/comment.svg'
import 'rucksack/icons/information.svg'
import 'rucksack/icons/reply.svg'
import 'rucksack/icons/thumbs-down.svg'
import 'rucksack/icons/thumbs-up.svg'
import 'rucksack/icons/facebook.svg'
import 'rucksack/icons/instagram.svg'
import 'rucksack/icons/telegram.svg'
import 'rucksack/icons/twitter.svg'
import 'rucksack/icons/web-share.svg'
import 'rucksack/icons/youtube.svg'
import 'rucksack/icons/magnifying-glass.svg'
import 'rucksack/icons/email.svg'
import 'rucksack/icons/facebook-color.svg'
import 'rucksack/icons/instagram-color.svg'
import 'rucksack/icons/telegram-color.svg'
import 'rucksack/icons/twitter-color.svg'
import 'rucksack/icons/youtube-color.svg'

import '../images/logo/cnmi_di_logo.svg'
import '../images/logo/logo.svg'
import '../images/graphics/four-o-four.svg'
// CSS
import '../stylesheets/application.scss'

// Scripts
import 'promise-polyfill/src/polyfill'

import {
  archiveSelector,
  articleRating,
  commentForm,
  commentReplyLink,
  goldenQuestion,
  feedbackForm,
  poll,
  newsletter,
  searchBar,
  searchResultsFilter,
  webShare,
  lazyloading
} from 'rucksack'

import { articleSlideshow } from '../typescript/slideshow'

const polyfills = []

if (
  !('IntersectionObserver' in window) ||
  !('IntersectionObserverEntry' in window) ||
  !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
) {
  polyfills.push(
    import(
      // @ts-expect-error: missing types
      /* webpackChunkName: 'polyfill~intersectionObserver' */ 'intersection-observer'
    )
  )
}

if (!('fetch' in window)) {
  polyfills.push(
    // @ts-expect-error: missing types
    import(/* webpackChunkName: "polyfill~fetch" */ 'whatwg-fetch')
  )
}

Promise.all(polyfills)
  .then(() => {
    if (document.readyState !== 'loading') {
      main()
    } else {
      document.addEventListener('DOMContentLoaded', main)
    }
  })
  .catch(error => {
    console.error('Failed in loading polyfills', error)
  })

const main = (): void => {
  lazyloading()
  articleSlideshow()
  goldenQuestion()

  const archiveData = window.ARCHIVE_DATA
  const yearSelect: HTMLSelectElement | null = document.querySelector(
    '#year.archive-selector__select'
  )
  const monthSelect: HTMLSelectElement | null = document.querySelector(
    '#month.archive-selector__select'
  )

  if (archiveData != null && yearSelect != null && monthSelect != null) {
    archiveSelector(archiveData, yearSelect, monthSelect)
  }

  const ratingForm: HTMLFormElement | null = document.querySelector(
    '.js-ratings'
  )

  if (ratingForm != null) {
    articleRating(ratingForm)
  }

  const commentFormNodeList: NodeListOf<HTMLFormElement> = document.querySelectorAll(
    '.js-comment'
  )

  if (commentFormNodeList.length > 0) {
    const commentFormArray: HTMLFormElement[] = Array.prototype.slice.call(
      commentFormNodeList
    )

    commentFormArray.forEach(form => {
      commentForm(form)
    })
  }

  const contactUsForm: HTMLFormElement | null = document.querySelector(
    '.js-feedback'
  )

  if (contactUsForm != null) {
    feedbackForm(contactUsForm)
  }

  const pollForm: HTMLFormElement | null = document.querySelector('.js-poll')
  if (pollForm != null) {
    poll(pollForm)
  }

  const newsletterForm: HTMLFormElement | null = document.querySelector(
    '.js-newsletter'
  )
  if (newsletterForm != null) {
    newsletter(newsletterForm)
  }

  const commentReplyLinksNodeList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    '.comment-section__reply-button'
  )
  if (commentReplyLinksNodeList.length > 0) {
    const replyLinkArray: HTMLAnchorElement[] = Array.prototype.slice.call(
      commentReplyLinksNodeList
    )

    replyLinkArray.forEach(link => {
      commentReplyLink(link)
    })
  }

  const searchForm: HTMLFormElement | null = document.querySelector(
    '.search-form'
  )

  if (searchForm != null) {
    searchBar(searchForm)
  }

  const filterSearchResultsForm: HTMLFormElement | null = document.querySelector(
    '.js-search'
  )

  if (filterSearchResultsForm != null) {
    searchResultsFilter(filterSearchResultsForm)
  }

  if (navigator.share !== undefined && typeof navigator.share === 'function') {
    const webShareIcon: HTMLAnchorElement | null = document.querySelector(
      '.shareAPI'
    )
    if (webShareIcon != null) {
      webShare(webShareIcon)
    }
  }
}
