import getCSRFToken from './csrfToken'

export default function request<Request, Response> (
  method: 'GET' | 'POST',
  url: string,
  content?: Request,
  callback?: (response: Response) => void,
  errorCallback?: (err: any) => void
): void {
  const request = new XMLHttpRequest()
  request.open(method, url, true)
  request.onload = function () {
    if (this.status >= 200 && this.status < 400) {
      // Success!
      const data = JSON.parse(this.response) as Response
      if (callback != null) {
        callback(data)
      }
    }
  }

  request.onerror = function (err) {
    // There was a connection error of some sort
    if (errorCallback != null) {
      errorCallback(err)
    }
  }
  if (method === 'POST') {
    request.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    )
    request.setRequestHeader('x-csrf-token', getCSRFToken())
  }

  if (method === 'GET') {
    request.setRequestHeader('Content-Type', 'application/json')
  }
  // @ts-expect-error: Arguement of type 'Request' is not assignable
  request.send(content)
}
