export const articleSlideshow = (): void => {
  const articleSlideshow: HTMLElement | null = document.querySelector(
    '.slideshow--article'
  )

  if (articleSlideshow != null) {
    import('siema')
      .then(({ default: Siema }) => {
        const prev = document.querySelector('.slideshow__button--prev')
        const next = document.querySelector('.slideshow__button--next')
        const htmlTag = document.querySelector('html')
        if (
          htmlTag != null &&
          document.querySelector('html')?.getAttribute('dir') != null
        ) {
          const RTL = htmlTag.getAttribute('dir') === 'rtl'
          const mySiema = new Siema({
            selector: articleSlideshow,
            easing: 'ease-out',
            perPage: 1,
            startIndex: 0,
            draggable: true,
            multipleDrag: false,
            threshold: 20,
            loop: true,
            rtl: RTL
          })
          if (prev != null && next != null) {
            prev.addEventListener('click', function () {
              return mySiema.prev()
            })
            next.addEventListener('click', function () {
              return mySiema.next()
            })
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}
