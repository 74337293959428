import { serialize } from './formHelpers'
import request from './request'
import { RatingResponse } from './types/interfaces'

export const goldenQuestion = (): void => {
  const gqForm: HTMLFormElement | null = document.querySelector('.js-gq')

  if (gqForm != null) {
    const gqButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
      '.golden-question__button'
    )
    const idHiddenInput: HTMLInputElement | null = gqForm.querySelector(
      '.answer_id'
    )

    const textHiddenInput: HTMLInputElement | null = gqForm.querySelector(
      '.answer_text'
    )

    Array.prototype.forEach.call(gqButtons, function (gqButton: {
      addEventListener: (arg0: string, arg1: (event: any) => void) => void
    }) {
      gqButton.addEventListener('click', function (event: {
        preventDefault: () => void
        currentTarget: HTMLButtonElement
      }) {
        event.preventDefault()

        const buttonClicked = event.currentTarget

        Array.prototype.forEach.call(gqButtons, function (
          gqButton: HTMLButtonElement
        ) {
          gqButton.disabled = true
        })

        const answerId = buttonClicked.dataset.answerId

        const answerText = buttonClicked.dataset.answerText

        if (idHiddenInput != null && answerId != null) {
          idHiddenInput.value = answerId
        }
        if (textHiddenInput != null && answerText != null) {
          textHiddenInput.value = answerText
        }

        const formData: { [k: string]: any } = {}
        const fields = Array.prototype.slice.call(gqForm.elements, 0)
        Array.prototype.forEach.call(fields, function (element: {
          name: string
          value: any
        }) {
          formData[element.name] = element.value
        })

        request('POST', gqForm.action, serialize(formData), gqServerResponse)
      })
    })
  }
}

function gqServerResponse (data: RatingResponse): void {
  const responseOutput: HTMLElement | null = document.querySelector(
    '.rating__question'
  )
  if (responseOutput != null) {
    if (!data.result) {
      const errors = data.errors
      if (errors != null) {
        responseOutput.textContent = errors[0]
      }
    } else {
      const successMsg = data.message
      if (successMsg != null) {
        responseOutput.textContent = successMsg
      }
    }
  }
}
