const CSRF_SELECTOR = 'meta[name=csrf-token]'
let token: string

export default function getCSRFToken (scope = document): string {
  if (token != null) {
    return token
  }
  const tokenNode = scope.querySelector(CSRF_SELECTOR)
  if (tokenNode != null) {
    const content = tokenNode.getAttribute('content')
    if (content != null) {
      token = content
      return token
    }
  }
  return ''
}

export function clearCSRFToken (): string {
  return (token = '')
}
