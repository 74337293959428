import { serialize } from './formHelpers'
import request from './request'
import { PollResponse } from './types/interfaces'

export const poll = (form: HTMLFormElement | null): void => {
  if (form != null) {
    const formBtns = form.querySelectorAll('button')
    if (formBtns.length > 0) {
      Array.prototype.forEach.call(formBtns, btn => {
        btn.addEventListener('click', (event: Event) => {
          const target = event.target as HTMLButtonElement | null

          Array.prototype.forEach.call(formBtns, btn => {
            btn.disabled = true

            if (btn.getAttribute('data-disable-with') != null) {
              btn.textContent = btn.getAttribute('data-disable-with')
            }
          })

          const valueInputToBeSent = form.querySelector('#option_id')
          if (target?.value !== undefined && valueInputToBeSent != null) {
            valueInputToBeSent.setAttribute('value', target.value)
          }

          const pollFormData: { [k: string]: string } = {}
          const hiddenInputs = form.querySelectorAll('input[type="hidden"]')
          if (hiddenInputs.length > 0) {
            Array.prototype.forEach.call(hiddenInputs, function (element: {
              name: string
              value: string
            }) {
              pollFormData[element.name] = element.value
            })
          }

          request(
            'POST',
            form.action,
            serialize(pollFormData),
            pluginPollResponse
          )
        })
      })
    }

    const viewResults = form.querySelector('a')
    if (viewResults != null) {
      viewResults.addEventListener('click', event => {
        event?.preventDefault()
        request('GET', viewResults.href, undefined, pluginPollResponse)
      })
    }

    const formContainer = form.parentElement
    if (formContainer != null) {
      const observer = new MutationObserver(mutations => {
        if (mutations != null) {
          const progressMeters: NodeListOf<HTMLProgressElement> = formContainer.querySelectorAll(
            '.progress-meter'
          )

          Array.prototype.forEach.call(progressMeters, progressMeter => {
            progressMeter.style.width = progressMeter.getAttribute('data-width')
            progressMeter.classList.add = 'animate'
          })
        }
      })

      observer.observe(formContainer, {
        childList: true
      })
    }
  }
}

function pluginPollResponse (data: PollResponse): void {
  const responseData = data
  const totalVotesLabelText: string = responseData.poll.votes_label
  const options = responseData.poll.options
  const fragment = document.createDocumentFragment()
  const totalVotes = document.createElement('p')
  totalVotes.className = 'poll__total-votes'
  totalVotes.textContent = `${totalVotesLabelText}: ${responseData.poll.votes}`
  fragment.appendChild(totalVotes)

  Array.prototype.forEach.call(options, function (option: {
    answer: string
    percentage: string
    votes: string
  }) {
    const answer = document.createElement('span')
    answer.className = 'poll__answer'
    answer.textContent = `${option.answer} - ${option.percentage}% (${option.votes})`
    const progress = document.createElement('progress')
    progress.className = 'progress-meter'
    progress.dataset.width = `${option.percentage}%`
    fragment.appendChild(answer)
    fragment.appendChild(progress)
  })

  const form: HTMLFormElement | null = document.querySelector('.js-poll')
  if (form != null) {
    const container = form.parentElement
    form.style.display = 'none'
    if (container != null) {
      container.appendChild(fragment)
      container.classList.add('poll__result-view')
    }
  }
}
