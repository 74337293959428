export function webShare (shareAPI: HTMLAnchorElement | null): void {
  if (shareAPI != null) {
    const URL = window.SHARE_URL

    const teaser = (document.getElementsByName(
      'description'
    )[0] as HTMLMetaElement).content

    const shareData = {
      title: document.title,
      text: teaser,
      url: URL
    }
    shareAPI.style.display = 'inline'

    shareAPI.addEventListener('click', function () {
      navigator
        .share(shareData)
        .then(() => {})
        .catch(error => {
          console.log('Couldnt share because of', error.message)
        })
    })
  }
}
