export const searchBar = (searchForm: HTMLFormElement | null): void => {
  if (searchForm != null) {
    const btn: HTMLButtonElement | null = searchForm.querySelector('button')
    if (btn != null) {
      searchForm.addEventListener('submit', (): void => {
        btn.disabled = true
      })
    }
  }
}
