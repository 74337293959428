export const lazyloading = (): void => {
  const lazyElements = document.querySelectorAll('[loading=lazy]')
  const lazyElementArray: HTMLImageElement[] = Array.prototype.slice.call(
    lazyElements
  )

  if ('loading' in HTMLImageElement.prototype) {
    lazyElementArray.forEach(function (mediaElement) {
      const imgSource = mediaElement.getAttribute('data-src')
      const mediaSrcset = mediaElement.getAttribute('data-srcset')
      const parentElement = mediaElement.parentElement

      if (imgSource != null) {
        mediaElement.setAttribute('src', imgSource)
      }

      if (mediaSrcset != null) {
        mediaElement.setAttribute('srcset', mediaSrcset)
      }

      if (parentElement != null && parentElement.nodeName === 'PICTURE') {
        const pictureSources = Array.prototype.slice.call(
          parentElement.getElementsByTagName('source')
        )
        pictureSources.forEach(
          (sourceElement: {
            getAttribute: (arg0: string) => null
            setAttribute: (arg0: string, arg1: any) => void
          }) => {
            if (sourceElement.getAttribute('data-srcset') != null) {
              sourceElement.setAttribute(
                'srcset',
                sourceElement.getAttribute('data-srcset')
              )
            }
            if (sourceElement.getAttribute('data-src') != null) {
              sourceElement.setAttribute(
                'src',
                sourceElement.getAttribute('data-src')
              )
            }
          }
        )
      }
    })
  } else {
    const options: IntersectionObserverInit = {
      rootMargin: '200px 0px',
      threshold: 0.01
    }

    const imageObserver = function (imgElement: HTMLImageElement): void {
      const io = new IntersectionObserver(function (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) {
        Array.prototype.slice
          .call(entries)
          .forEach(function (entry: IntersectionObserverEntry) {
            if (entry.isIntersecting) {
              const imgElement = entry.target as HTMLImageElement
              const imgSrc = imgElement.getAttribute('data-src')
              const imgSrcset = imgElement.getAttribute('data-srcset')
              const imgParent = imgElement.parentElement

              if (imgSrc != null) {
                imgElement.setAttribute('src', imgSrc)
              }
              if (imgSrcset != null) {
                imgElement.setAttribute('srcset', imgSrcset)
              }

              if (imgParent != null && imgParent.nodeName === 'PICTURE') {
                const imgSources = Array.prototype.slice.call(
                  imgParent.getElementsByTagName('source')
                )

                imgSources.forEach(
                  (sourceElement: {
                    getAttribute: (arg0: string) => null
                    setAttribute: (arg0: string, arg1: any) => void
                  }) => {
                    if (sourceElement.getAttribute('data-srcset') != null) {
                      sourceElement.setAttribute(
                        'srcset',
                        sourceElement.getAttribute('data-srcset')
                      )
                    }
                    if (sourceElement.getAttribute('data-src') != null) {
                      sourceElement.setAttribute(
                        'src',
                        sourceElement.getAttribute('data-src')
                      )
                    }
                  }
                )
              }

              observer.unobserve(imgElement)
            }
          })
      },
      options)
      io.observe(imgElement)
    }

    lazyElementArray.forEach(imageObserver)
  }

  lazyElementArray.forEach(function (
    mediaElement: HTMLImageElement | HTMLVideoElement | HTMLAudioElement
  ) {
    mediaElement.onerror = function () {
      mediaElement.style.display = 'none'
    }
  })
}
