import { CommentResponse } from './types/interfaces'
import { serialize } from './formHelpers'
import request from './request'

export const commentForm = (form: HTMLFormElement | null): void => {
  if (form != null) {
    form.addEventListener('submit', event => {
      event.preventDefault()
      const currentForm = event.target as HTMLFormElement

      const btn = form.querySelector('button')

      if (btn != null) {
        btn.disabled = true

        if (btn.getAttribute('data-disable-with') != null) {
          btn.textContent = btn.getAttribute('data-disable-with')
        }
      }

      const formData: { [k: string]: string } = {}
      const fields = Array.prototype.slice.call(form.elements, 0)
      Array.prototype.forEach.call(fields, function (element: {
        name: string
        value: string
      }) {
        formData[element.name] = element.value
      })

      request(
        'POST',
        form.action,
        serialize(formData),
        function pluginCommentResponse (data: CommentResponse): void {
          const responseData = data
          if (responseData.errors != null) {
            const previousErrors = currentForm.querySelectorAll(
              '.user-form__error'
            )

            if (previousErrors.length > 0) {
              Array.prototype.forEach.call(previousErrors, function (
                err: HTMLDivElement
              ) {
                err.style.display = 'none'
              })
            }

            const errorMessage = document.createElement('div')
            errorMessage.className = 'user-form__error'

            if (responseData.errors != null) {
              errorMessage.textContent = responseData.errors[0]
            }

            currentForm.insertAdjacentElement('afterbegin', errorMessage)

            const btn = currentForm.querySelector('button')
            if (btn != null) {
              btn.disabled = false
              if (btn.getAttribute('data-original') != null) {
                btn.textContent = btn.getAttribute('data-original')
              }
            }
          } else if (
            responseData.comment != null &&
            responseData.message != null
          ) {
            const commentFragment = document.createDocumentFragment()
            const userHeading = document.createElement('h5')
            userHeading.className = 'user-form__response'
            userHeading.textContent = responseData.message

            const commentContainer = document.createElement('div')
            commentContainer.className = 'reader-comment-container'

            const parsedComment = JSON.parse(responseData.comment)

            const dateNode = document.createElement('span')
            const icoDate = parsedComment.date
            const parseDate = Date.parse(icoDate)
            dateNode.textContent = new Date(parseDate).toLocaleDateString()
            commentContainer.insertAdjacentElement('afterbegin', dateNode)

            const newComment = document.createElement('p')
            commentContainer.insertAdjacentElement('beforeend', newComment)
            newComment.textContent = parsedComment.body.replace(
              /(<([^>]+)>)/gi,
              ''
            )

            commentFragment.appendChild(userHeading)
            commentFragment.appendChild(commentContainer)

            const userComment = commentFragment
            currentForm.style.display = 'none'
            currentForm.reset()
            const parent = currentForm.parentNode
            if (parent != null) {
              parent.insertBefore(userComment, form)
            }
          }
        }
      )
    })

    const textArea = form.querySelector('textarea')
    const label = form.querySelector('.js-chars-remaining')

    if (textArea != null && label != null) {
      textArea.addEventListener('input', () => {
        const maxLength = textArea.getAttribute('maxlength')
        if (maxLength != null) {
          const maxLengthValue = parseInt(maxLength)
          const currentLength = textArea.value.length

          const characterCount = maxLengthValue - currentLength
          label.textContent = `${characterCount} / ${maxLengthValue}`
        }
      })
    }
  }
}
