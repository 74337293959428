export const searchResultsFilter = (
  sortSearch: HTMLFormElement | null
): void => {
  if (sortSearch != null) {
    const radioBtns: NodeListOf<HTMLInputElement> = sortSearch.querySelectorAll(
      "input[type='radio']"
    )

    if (radioBtns.length > 0) {
      Array.prototype.forEach.call(radioBtns, btn => {
        const isChecked = btn.getAttribute('checked')
        if (isChecked == null) {
          btn.addEventListener('click', () => sortSearch.submit())
        }
      })
    }
  }
}
